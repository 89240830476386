import React, { type SVGProps } from 'react';

const BrandGobazarLongIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      height="24px"
      viewBox="0 0 146 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5127 2.52852C12.9944 0.557049 14.9322 -0.419504 17.3843 0.171326C19.8394 0.759094 21.1405 2.4826 21.4987 4.94694C21.4711 8.91744 19.3405 11.8226 16.6924 14.4798C16.2241 14.9512 15.9271 14.8227 15.5138 14.4155C14.0965 13.0257 12.8597 11.5073 11.9413 9.73787C10.824 7.57966 10.3893 5.35716 11.2556 3.00302L11.2648 2.98159C11.3474 2.83159 11.4301 2.68159 11.5097 2.53464L11.5127 2.52852ZM16.0894 5.64186C17.1761 5.64186 18.2659 5.62043 19.3527 5.65104C19.9129 5.66635 20.0354 5.44593 19.9741 4.93164C19.7782 3.18976 18.0517 1.63156 16.2271 1.58258C14.3414 1.5336 12.5383 3.04282 12.2934 4.84592C12.2199 5.38471 12.2964 5.67859 12.9454 5.6541C13.9924 5.61431 15.0393 5.64186 16.0863 5.64186H16.0894Z"
        fill="#56BCDC"
      />
      <path
        d="M11.9454 9.73471C12.8608 11.5041 14.1006 13.0225 15.518 14.4124C15.9312 14.8195 16.2282 14.945 16.6966 14.4767C19.1701 11.9939 21.1905 9.29388 21.4691 5.70911H10.8066C10.8434 7.08363 11.2658 8.42141 11.9454 9.73165V9.73471Z"
        fill="#56BCDC"
      />
      <path
        d="M26.8359 5.70911H22.9114C22.3603 10.1419 19.9817 13.5154 16.7276 16.393C16.3541 16.7236 16.0633 16.8889 15.6102 16.4849C12.3469 13.5736 9.89477 10.197 9.35292 5.70911H5.23243C4.05996 5.70911 3.05892 6.56321 2.87524 7.72038L0.885403 21.239C0.655806 22.687 1.77624 24.0003 3.24259 24.0003H28.8258C30.2921 24.0003 31.4126 22.6901 31.183 21.239L29.1931 7.72038C29.0094 6.56015 28.0084 5.70911 26.8359 5.70911Z"
        fill="#56BCDC"
      />
      <path
        d="M8.11839 5.58645C7.80614 5.58951 7.49389 5.59563 7.18164 5.59869C7.49083 5.35379 7.80308 5.35991 8.11839 5.58645Z"
        fill="#F6EFF7"
      />
      <path
        d="M11.5125 2.52856C11.4298 2.67857 11.3472 2.82857 11.2676 2.97551C11.2768 2.78571 11.3288 2.6204 11.5125 2.52856Z"
        fill="#F6EFF7"
      />
      <path
        d="M25.3301 5.62098H25.2137L25.1035 5.58731C25.1954 5.48629 25.2688 5.51384 25.3301 5.62098Z"
        fill="#F6EFF7"
      />
      <path
        d="M16.0969 5.64198C15.05 5.64198 13.9999 5.61136 12.956 5.65422C12.3071 5.67871 12.2305 5.38789 12.304 4.84604C12.5489 3.04294 14.352 1.53372 16.2377 1.5827C18.0623 1.63168 19.7888 3.18988 19.9848 4.93176C20.0429 5.44912 19.9205 5.66953 19.3633 5.65116C18.2766 5.62055 17.1898 5.64198 16.1 5.64198H16.0969Z"
        fill="white"
      />
      <path
        d="M50.9149 16.0752H45.9066V12.1139H55.1302V20.1222C54.1292 21.2151 52.9261 22.06 51.521 22.6539C50.1159 23.2478 48.6587 23.5448 47.1495 23.5448C44.0361 23.5448 41.4708 22.5958 39.4534 20.7008C37.436 18.8059 36.4258 16.3385 36.4258 13.3017C36.4258 10.2649 37.4054 7.91687 39.3677 5.97294C41.33 4.02902 43.7637 3.05859 46.6688 3.05859C48.2148 3.05859 49.7546 3.36166 51.2822 3.96474C52.8098 4.56781 53.9425 5.45559 54.6772 6.625L51.6496 9.34037C50.4801 7.98115 48.876 7.30155 46.8403 7.30155C45.0096 7.30155 43.5769 7.84339 42.5392 8.93015C41.5014 10.0139 40.984 11.471 40.984 13.3017C40.984 15.1323 41.5596 16.6354 42.7106 17.7008C43.8616 18.7661 45.3525 19.2988 47.1801 19.2988C48.576 19.2988 49.822 18.9222 50.9149 18.1661V16.0722V16.0752Z"
        fill="#56BCDC"
      />
      <path
        d="M56.8281 15.8207C56.8281 13.5584 57.5934 11.7185 59.121 10.3012C60.6486 8.88685 62.5344 8.17969 64.7813 8.17969C67.0283 8.17969 68.9692 8.89297 70.4968 10.3165C72.0244 11.74 72.7897 13.5767 72.7897 15.8207C72.7897 18.0646 72.0152 19.9197 70.4692 21.3249C68.9233 22.73 67.0253 23.4341 64.7813 23.4341C62.5374 23.4341 60.6486 22.7178 59.121 21.2851C57.5934 19.8524 56.8281 18.0309 56.8281 15.8237V15.8207ZM61.0741 15.8207C61.0741 16.8952 61.4292 17.7983 62.1364 18.5238C62.8436 19.2493 63.7252 19.6136 64.7813 19.6136C65.914 19.6136 66.8232 19.2646 67.512 18.5667C68.2008 17.8687 68.5437 16.9534 68.5437 15.8207C68.5437 14.688 68.1947 13.7941 67.4967 13.0747C66.7987 12.3584 65.8926 12.0002 64.7813 12.0002C63.6701 12.0002 62.7731 12.3584 62.0935 13.0747C61.4139 13.791 61.0741 14.7064 61.0741 15.8207Z"
        fill="#56BCDC"
      />
      <path
        d="M78.7271 23.2046H74.625V2.55005H78.7271V9.48082C79.0853 9.04611 79.6608 8.72162 80.4537 8.50426C81.2466 8.28691 82.0088 8.17977 82.7466 8.17977C84.9354 8.17977 86.6191 8.86244 87.7977 10.2308C88.9763 11.5992 89.5671 13.3962 89.5671 15.6218C89.5671 17.8473 88.9151 19.8249 87.614 21.2668C86.313 22.7087 84.5374 23.4311 82.2935 23.4311C80.8976 23.4311 79.7098 23.1587 78.7271 22.6107V23.2046ZM81.8956 12.0003C80.2731 12.0003 79.2169 12.6431 78.7271 13.9258V17.8014C79.4251 19.0076 80.4812 19.6137 81.8956 19.6137C83.0466 19.6137 83.9038 19.2616 84.4701 18.5514C85.0364 17.8412 85.3181 16.9136 85.3181 15.7626C85.3181 14.6115 85.0242 13.6748 84.4395 13.0044C83.8548 12.3339 83.0068 12.0003 81.8925 12.0003H81.8956Z"
        fill="#353434"
      />
      <path
        d="M93.4443 13.4138L91.4912 10.4413C92.2075 9.66674 93.1657 9.09734 94.3627 8.72999C95.5596 8.36263 96.7627 8.17896 97.9719 8.17896C99.876 8.17896 101.358 8.71774 102.414 9.79226C103.47 10.8668 104 12.3668 104 14.2924V23.2068H99.8975V21.8782C99.4811 22.3497 98.8934 22.717 98.128 22.9834C97.3658 23.2466 96.6341 23.3783 95.9362 23.3783C94.3902 23.3783 93.1075 22.9528 92.0881 22.1048C91.0687 21.2568 90.5605 20.0782 90.5605 18.569C90.5605 17.4179 90.9187 16.4659 91.6351 15.7097C92.3514 14.9567 93.1871 14.4362 94.1392 14.1546C95.0913 13.873 96.1229 13.7291 97.2372 13.7291C98.3515 13.7291 99.2362 13.8515 99.8975 14.0964C99.9342 13.3801 99.7475 12.8505 99.3311 12.5107C98.8964 12.1709 98.2934 12.0025 97.5189 12.0025C96.1413 12.0025 94.7821 12.4739 93.4443 13.4168V13.4138ZM96.7841 19.8364C97.4056 19.8364 98.0148 19.6864 98.6087 19.3833C99.2026 19.0802 99.6311 18.6761 99.8975 18.1649V17.4853C99.2362 17.2404 98.4923 17.1179 97.6627 17.1179C97.0199 17.1179 96.475 17.173 96.0219 17.2863C95.2106 17.5496 94.8035 17.9751 94.8035 18.5598C94.8035 19.0129 95.0055 19.3374 95.4127 19.5364C95.8168 19.7353 96.276 19.8333 96.7841 19.8333V19.8364Z"
        fill="#353434"
      />
      <path
        d="M113.05 12.3119H106.174V8.49146H119.163V11.1242L111.835 19.3866H119.023V23.2071H105.752V20.5468L113.053 12.3119H113.05Z"
        fill="#353434"
      />
      <path
        d="M122.87 13.4145L120.917 10.442C121.633 9.66748 122.591 9.09808 123.788 8.73072C124.985 8.36337 126.188 8.17969 127.398 8.17969C129.302 8.17969 130.783 8.71848 131.84 9.79299C132.896 10.8675 133.425 12.3675 133.425 14.2931V23.2076H129.323V21.879C128.907 22.3504 128.319 22.7178 127.554 22.9841C126.792 23.2474 126.06 23.379 125.362 23.379C123.816 23.379 122.533 22.9535 121.514 22.1055C120.494 21.2575 119.986 20.0789 119.986 18.5697C119.986 17.4187 120.344 16.4666 121.061 15.7105C121.777 14.9574 122.613 14.437 123.565 14.1553C124.517 13.8737 125.549 13.7298 126.663 13.7298C127.777 13.7298 128.662 13.8523 129.323 14.0972C129.36 13.3808 129.173 12.8512 128.757 12.5114C128.322 12.1716 127.719 12.0032 126.945 12.0032C125.567 12.0032 124.208 12.4747 122.87 13.4176V13.4145ZM126.21 19.8371C126.831 19.8371 127.441 19.6871 128.034 19.384C128.628 19.081 129.057 18.6769 129.323 18.1656V17.486C128.662 17.2411 127.918 17.1187 127.089 17.1187C126.446 17.1187 125.901 17.1738 125.448 17.287C124.636 17.5503 124.229 17.9758 124.229 18.5605C124.229 19.0136 124.431 19.3381 124.838 19.5371C125.243 19.7361 125.702 19.834 126.21 19.834V19.8371Z"
        fill="#353434"
      />
      <path
        d="M139.995 23.2041H135.893V8.49149H139.995V9.08538C140.181 8.82211 140.512 8.60782 140.987 8.44863C141.458 8.28944 141.884 8.20679 142.26 8.20679C143.506 8.20679 144.467 8.63231 145.147 9.48028L143.053 12.762C142.958 12.5722 142.771 12.4283 142.487 12.3242C142.205 12.2201 141.948 12.1681 141.724 12.1681C140.855 12.1681 140.282 12.6763 139.998 13.6957V23.2041H139.995Z"
        fill="#353434"
      />
    </svg>
  );
};

export default BrandGobazarLongIcon;
